
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Push the footer to the bottom */
main {
  flex-grow: 1;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #000000;
  }

  header {
    background: linear-gradient(to right, #007991, #78ffd6);
    color: white;
    text-align: center;
    padding: 0.2em 0.2em; /* Reduce the top and bottom padding */
    z-index: 93;
  }

  header h1 {
    margin-top: 0;
    margin-bottom: 0.5em; /* Adjust the bottom margin of the title */
    font-size: 1.5em; /* Smaller font size for the title */
    line-height: 1.2; /* Less space between lines of text */
    z-index: 3;
  }

  .video-container {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  footer {
    background: linear-gradient(to left, #007991, #78ffd6);
    color: white;
    text-align: center;
    padding: 0.2em 0.2em; /* Reduce the top and bottom padding */
    margin-top: auto; /* This will push the footer to the bottom */
    z-index: 3;
  }

  footer p {
    margin-top: 0;
    margin-bottom: 0; /* Remove default margins */
    font-size: 0.9em; /* Adjust font size if necessary */
    line-height: 1.2; /* Adjust line height if necessary */
  }

  footer h2 {
    margin-bottom: 0;
    margin-top: 0.5em; /* Adjust the bottom margin of the title */
    font-size: 1.2em; /* Smaller font size for the title */
    line-height: 1.2; /* Less space between lines of text */
  }

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25%;
  height: 360px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 25;
}

.arrow-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.arrow-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: white;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  z-index: 30;
}

.arrow-icon.left {
  transform: rotate(-90deg);
  margin-left: 33%;
}

.arrow-icon.right {
  transform: rotate(90deg);
  margin-right: 33%;

}

.arrow.prev {
  left: 0;
}

.arrow.next {
  right: 0;
}

.video-carousel-container {
  position: relative;
  background-color: black; /* Set the background to black */
  padding-top: 20px; /* Add 20px padding to the top */
  padding-bottom: 20px; /* Add 20px padding to the bottom */
}
/* Custom styling for the dots */
.custom-dots {
  position: absolute;
  display: flex;
  justify-content: center;
  list-style: block;
  padding: 0;
  bottom: -8.5%; /* Adjust this value to set the fixed distance from the bottom of the carousel */
  left: 0;
  right: 0;
}

.custom-dots li {
  margin: -5px 15px;
}

.custom-dots li button {
  font-size: 0; /* hides the default number */
  line-height: 0; /* hides the default number */
  display: block;
  width: 10px; /* size of the dot */
  height: 10px; /* size of the dot */
  padding: 5px; /* space around the dot */
  cursor: pointer;
  border: 0;
  background: #007991; /* color of non-active dots */
  border-radius: 25%; /* makes it rounded */
  z-index:19;
}

.custom-dots li.slick-active button {
  background: #78ffd6; /* color of the active dot */
}

.playlist-thumbnails {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%; /* space around the dot */
  background-color: black;
  z-index:39;


}

.playlist-thumbnails button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  z-index:39;

}

.playlist-thumbnails img {
  width: 100px; /* Adjust thumbnail size */
  height: auto;
  z-index:39;

  /* Add more styles as needed */
}

.playlist-description {
  color: white; /* Set text color to white */
  text-align: justify; /* Justify text for even spacing */
  text-justify: inter-word; /* Even spacing between words */
  padding: 0 20px; /* Add padding on the sides if needed */
  margin-top: 20px; /* Add some space above the paragraph */
  margin-bottom: 20px;
}

.video-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
}

/* Ensures the iframe fills the .video-slide container */
.video-slide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.thumbnail-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black; /* In case the thumbnail doesn't cover the whole area */
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Ensure the thumbnail covers the area without distortion */
}

.play-icon {
  position: absolute;
  font-size: 48px; /* Adjust size as needed */
  color: white;
  cursor: pointer;
}
